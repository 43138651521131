import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography, TextField, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const Cards = ({ order, image, title, price, tracking, status, track }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [pedido, setPedido] = useState("");
  const [amazonTracking, setAmazonTracking] = useState("");
  const [infoAdicional, setInfoAdicional] = useState("");

  const handlePedidoChange = (event) => {
    setPedido(event.target.value);
  };

  const handleAmazonTrackingChange = (event) => {
    setAmazonTracking(event.target.value);
  };

  const handleInfoAdicionalChange = (event) => {
    setInfoAdicional(event.target.value);
  };

  return (
    <Box
      sx={{
        background: colors.primary[400],
        padding: { xs: "12px", md: "30px" },
        width: "100%",
        borderRadius: "15px",
        boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        color: colors.primary[100],
        margin: "5px",
      }}
    >

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginBottom: "10px", marginTop: "15px", height: "40px",  marginLeft:'15px' }}>
          <Typography variant="body1" sx={{ paddingLeft: "20px", fontFamily: 'Arial, sans-serif', color: colors.primary[100] }}>Orden Número: {order}</Typography>
        </Grid>

        <Grid item xs={12} md={3} sx={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Box sx={{ display: "flex", width: {xs:'80%', md: '190px'} , height:{xs:'200px', md: '100px'} , objectFit: 'contain', borderRadius: '20px', bgcolor: "white", padding: "5px" }}>
            <img src={image} alt={title} style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: '20px' }} />
          </Box>
        </Grid>

        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: {xs:'center', md: 'flex-start'}}}>
          <Typography variant="h5" sx={{ paddingLeft: "10px", fontFamily: 'Arial, sans-serif', color: colors.primary[100] }}>{title}</Typography>
          <Typography variant="h5" sx={{ paddingLeft: "10px", fontFamily: 'Arial, sans-serif', color: colors.primary[100] }}>{price}</Typography>
        </Grid>

        <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
            Descargar Etiqueta
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginBottom: "15px", height: "40px", marginTop: "25px", marginLeft:'15px'}}>
          <Typography variant="body1" sx={{ paddingLeft: "20px", fontFamily: 'Arial, sans-serif', color: colors.primary[100] }}>Número de Guía: {track}</Typography>
        </Grid>

        <Grid item xs={12} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginBottom: "15px", height: "40px" , marginLeft:'15px'}}>
          <Typography variant="body1" sx={{ paddingLeft: "20px", fontFamily: 'Arial, sans-serif', color: colors.primary[100] }}>Estado: {status}</Typography>
        </Grid>

        <Grid item xs={12} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginBottom: "10px", height: "40px" , marginLeft:'15px'}}>
          <Typography variant="body1" sx={{ paddingLeft: "20px", fontFamily: 'Arial, sans-serif', color: colors.primary[100] }}>Guía USA: {tracking}</Typography>
        </Grid>

        <Grid item xs={12} md={9} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginTop: "15px", height: "40px", textAlign: 'center' ,marginLeft:'15px'}}>
          <TextField
            value={pedido}
            onChange={handlePedidoChange}
            placeholder="Número de Pedido"
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                color: colors.primary[100], // Ensures text color is white
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                height: '15px',
                padding: '0 14px',
                '& input::placeholder': {
                  color: colors.primary[100], // Change the placeholder color here
                  opacity: 1, // Ensure placeholder is fully opaque
                },
                fontSize: '16px', // Match the font size
              },
            }}
            sx={{ background: 'transparent', borderRadius: '10px', textAlign: 'center' }} // Center text inside input
          />
        </Grid>

        <Grid item xs={12} md={2.5} sx={{ alignItems: "center" }}>
          <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
            Guardar
          </Button>
        </Grid>

        <Grid item xs={12} md={9} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginTop: "15px", height: "40px", textAlign: 'center' , marginLeft:'15px'}}>
          <TextField
            value={amazonTracking}
            onChange={handleAmazonTrackingChange}
            placeholder="Tracking de Amazon"
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                color: colors.primary[100], // Ensures text color is white
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                height: '15px',
                padding: '0 14px',
                '& input::placeholder': {
                  color: colors.primary[100], // Change the placeholder color here
                  opacity: 1, // Ensure placeholder is fully opaque
                },
                fontSize: '16px', // Match the font size
              },
            }}
            sx={{ background: 'transparent', borderRadius: '10px', textAlign: 'center' }} // Center text inside input
          />
        </Grid>

        <Grid item xs={12} md={2.5} sx={{ alignItems: "center" }}>
          <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
            Guardar
          </Button>
        </Grid>

        <Grid item xs={12} md={9} sx={{ background: colors.primary[980], padding: '30px', borderRadius: '10px', marginTop: "15px", marginBottom: "15px", height: "40px", textAlign: 'center', marginLeft:'15px' }}>
          <TextField
            value={infoAdicional}
            onChange={handleInfoAdicionalChange}
            placeholder="Información Adicional"
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                color: colors.primary[100], 
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                height: '15px',
                padding: '0 14px',
                '& input::placeholder': {
                  color: colors.primary[100], 
                  opacity: 1, 
                },
                fontSize: '16px', 
              },
            }}
            sx={{ background: 'transparent', borderRadius: '10px', textAlign: 'center' }} 
          />
        </Grid>

        <Grid item xs={12} md={2.5} sx={{ alignItems: "center" }}>
          <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cards;

import Box from '@mui/material/Box';
import { Grid, useTheme, Typography, TextField, Button } from '@mui/material';
import { tokens } from '../../../theme';
import { useState } from 'react';

const TypeSettings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%" }}>
                <Grid container spacing={4}>

                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif'}}>Modifica el tipo de publicación</Typography>
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
                            Clasica
                        </Button>
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #FF5733, #FF0000)" }}>
                            Premium
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                        <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
                            Guardar
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    );
};

export default TypeSettings;

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import ShopPublisher from '../ShopPublisher/ShopPublisher';
import UnitPublisher from '../UnitPublisher/UnitPublisher';
import SearchPublisher from '../SearchPublisher/SearchPublisher';
import CSVPublisher from '../CSVPublisher/CSVPublisher';

const ContentComponent = ({activepage}) => {

  return (
    <Box>
      <Grid container spacing={2} sx={{ padding: { xs: "10px", md: "20px" } }} justifyContent={"space-around"}>
        <Grid item xs={12}>
          {activepage === 'shop' && <ShopPublisher renderCard={3}/>}
          {activepage === 'unit' && <UnitPublisher/>}
          {activepage === 'search' && <SearchPublisher/>}
          {activepage === 'csv' && <CSVPublisher/>}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentComponent;

import Box from '@mui/material/Box';
import { Grid, Typography} from '@mui/material';
import ContentComponent from '../../components/Publisher/ContentComponent/ContentComponent';
import { useParams } from 'react-router-dom';
import BarComponent from '../../components/Publisher/BarComponent/BarComponent';

const Publisher = () => {
    const { activepage } = useParams(); 
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} sx={{marginLeft: { xs: "15px", md: "0px" }}}>
                                <Typography variant="h3" sx={{fontWeight:"700"}}>Publicador de Productos</Typography>
                                <Typography variant="h5" sx={{marginRight:"10px", marginTop:"5px"}}>En esta sección podrás realizar la publicacion de tus productos en tu tienda de MercadoLibre</Typography>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                            <BarComponent activepage={activepage}/>
                            </Grid>
                            <Grid item xs={12}>
                            <ContentComponent activepage={activepage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Publisher;
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Grid, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';

const UserBanner = ({image, name, city, time, total}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Box sx={{ background: colors.primary[400], borderRadius: "15px", height: "250px", padding: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }}>
      <Grid container spacing={1} sx={{height:"100%"}}>
        <Grid item xs={5}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "15px",
              overflow: "hidden",
              padding:"5px" // Asegura que el contenido dentro de la caja no se desborde
            }}
          >
            <img
              src="/discover.jpg"
              alt='portada'
              style={{
                width: "100%", // Cambia el ancho de la imagen al 100% para que ocupe todo el espacio disponible
                height: "100%", // Cambia la altura de la imagen al 100% para que ocupe todo el espacio disponible
                objectFit: "cover", // Ajusta la forma en que la imagen se ajusta dentro de su contenedor
                borderRadius: "15px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} container direction="column" justifyContent="space-between" marginLeft={"10px"}>
          <Grid item>
            <Grid container spacing={2} maxHeight={"170px"}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center" sx={{marginTop:"0px"}}>
                  <Grid item xs={2}>
                    <Avatar
                      src={image}
                      alt="David"
                      sx={{ width: {xs:30, md:40}, height: {xs:30, md:40}}}
                    />
                  </Grid>
                  <Grid item sx={{ml:"10px"}}>
                    <Typography variant="h4" component="h2" >
                      {name}
                    </Typography>
                    <Typography variant="body1">
                      {city}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" component="h3">
                  Tiempo
                </Typography>
                <Typography variant="h6" sx={{color:"#0064FB"}}>
                  {time}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" component="h3">
                  Total vendido
                </Typography>
                <Typography variant="h6" sx={{color:"#0064FB"}}>
                  {total}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2}}>
              <Button variant="contained" sx={{ width: "100%", mt: "15px", borderRadius: "10px", background: "linear-gradient(to right, #0064FB, #1ECDF8)", height: "50px" }} onClick={()=>{navigate("/settings/profile")}}>
                Ver Perfil
              </Button>
              <Button variant="contained" sx={{ width: "100%", mt: "15px", borderRadius: "10px", background: "linear-gradient(to right, #990000, #FF0000)" }} onClick={()=>{navigate("/results")}}>
                Ver Resultados
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>

  )
};

export default UserBanner;

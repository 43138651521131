import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography, TextField, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const UpdateAccount = ({ image, name, city, profile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [localName, setLocalName] = useState(name);

  const handleNameChange = (event) => {
    setLocalName(event.target.value);
  };

  return (
    <Box
      sx={{
        background: colors.primary[400],
        padding: { xs: "12px", md: "30px" },
        width: "100%",
        borderRadius: "15px",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        marginBottom: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px" }}>Nombre Completo</Typography>
        </Grid>

        <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: "10px" }}>
          <TextField
            value={localName}
            onChange={handleNameChange}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                color: colors.primary[100],
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                height: '25px',
                padding: '0 14px',
              },
            }}
            sx={{ background: 'transparent', borderRadius: '10px' }}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start'}}>
        <Box sx={{ display: "flex", width: '50px', height: '50px', objectFit: 'contain', borderRadius: '50px', bgcolor: "white", padding: "5px", marginTop: '15px' , marginRight:'15px'}}>
            <img src={image} style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: '20px' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' , marginTop:'13px'}}>
            <Typography variant="h4">{profile}</Typography>
            <Typography variant="h4">{city}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={3} sx={{ alignItems: "center" }}>
          <Button variant="contained" color="primary" fullWidth sx={{ marginTop: '20px', textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateAccount;

import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from '@mui/material';
import UpdateProfile from './UpdateProfile';
import UpdateAccount from './UpdateAccount';
import { tokens } from '../../../theme';

const MenuItem = ({ label }) => (
  <Typography
    variant="h5"
    sx={{
      marginRight: '20px', 
      cursor: 'pointer',
      color: 'white',
      position: 'relative', // Para manejar el fondo con padding sin afectar el tamaño del texto
      padding: '5px', // Espacio alrededor del texto
      '&:hover::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        
        borderRadius: '5px',
        zIndex: -1, // Coloca el fondo detrás del texto
      },
    }}
  >
    {label}
  </Typography>
);

const ProfileSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', borderRadius: '15px', padding: '5px' }}>

      <Box sx={{ padding: { xs: '0px', md: '20px' }, width: '100%', borderRadius: '20px' }}>
        <Typography variant="h3" sx={{ fontWeight: '700', marginBottom: '20px' }}>
          Configuración
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>
              Cuenta
            </Typography>
            <UpdateAccount
              image={"/profile.jpg"}
              name={"Osneider Scarpetta"}
              city={"Bogotá D.C"}
              profile={"Osneider Scarpetta"}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ marginBottom: '20px' }}>
              Actualizar Perfil
            </Typography>
            <UpdateProfile
              email={"scarpetta@voortex.com.co"}
              password={"Contraseña"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileSettings;

import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PublicIcon from '@mui/icons-material/Public';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Item = ({ title, to, icon, selected, setSelected, isCollapsed, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  

  const handleClick = () => {
    setSelected(title);
  };



  return (
    <div onClick={handleClick} style={{ position: "relative" }}>
      <MenuItem
        active={selected === title}
        style={{
          color: "white",
          backgroundColor: colors.primary[500],
          height: isCollapsed ? "40px" : "40px",
          width: isCollapsed ? "40px" : "auto",
          borderRadius: isCollapsed ? "50%" : "20px",
          marginTop: "15px",
          marginRight: "18px",
          marginLeft: "18px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: isCollapsed ? "0" : "0 10px",
          position: "relative", 
          boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"// Añadir posición relativa para que el enlace se superponga correctamente
        }}
        onClick={onClick}
      >
        {isCollapsed ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "16px"
            }}
          >
            {icon}
          </div>
        ) : (
          <Grid container spacing={5} sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "10px",
            width: "200px"
          }}>
            <Grid item xs={4} sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}>
              {icon}
            </Grid>
            <Grid item xs={8}>
              <Typography>{title}</Typography>
            </Grid>
          </Grid>
        )}
        <Link to={to} style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }} />
      </MenuItem>
    </div>
  );
};


const Sidebar = ({ isCollapsed, handleSidebarToggle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const mode = theme.palette.mode;
  const { logout } = useAuth0();

  const location = useLocation()
  const [selected, setSelected] = useState('');

  const handlelogOutClick = () => {
    logout()
  };

  useEffect(() => {
    const mainRoute = location.pathname.split('/')[1]; // Obtener la ruta principal
    const routeTitles = {
      home: 'Dashboard',
      settings: 'Configuración',
      shop: 'Tienda',
      results: 'Resultados',
      shipping: 'Entregas',
      publisher: 'Publicador',
      logout: 'Cerrar Sesión'
    };
    setSelected(routeTitles[mainRoute]);
  }, [location.pathname]);


  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-menu-item:hover": {
          border: "2.2px solid #1ECDF8 !important",
        },
        "& .logo-menu-item:hover": {
          border: "none !important",
        },
        "& .pro-menu-item.active": {
          color: `#040A2F !important`,
          background: "linear-gradient(to right, #0064FB, #1ECDF8) !important",
          border: "none !important",
        },
        height: "100vh",
        transition: "width 0.3s",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={handleSidebarToggle}
            icon={
              isCollapsed ? (
                <Box
                  sx={{
                    width: "50px",
                    height: "35px",
                    bgcolor: "#f7f7f7",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                  }}
                >
                  <img
                    src="/icono.png"
                    alt="icono"
                    className="logo-img"
                    style={{ height: "25px", width: "30px", objectFit: "contain" }}
                  />
                </Box>
              ) : undefined
            }
            className="logo-menu-item"
            style={{
              margin: "10px 0 20px 0",
              border: "none !important",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
                ml="10px"
                onClick={handleSidebarToggle}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  {mode === "dark" ? (
                    <img
                      src="/logo.png"
                      alt="logo"
                      className="logo-img"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  ) : (
                    <img
                      src="/logo.png"
                      alt="logo"
                      className="logo-img"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                </Box>
              </Box>
            )}
          </MenuItem>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "85vh",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Item
                title="Dashboard"
                to="/home"
                icon={<SpaceDashboardIcon />}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Configuración"
                to="/settings/profile"
                icon={<SettingsIcon />}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Tienda"
                to="/shop/talk"
                icon={<ShoppingBagIcon />}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Resultados"
                to="/results"
                icon={<AnalyticsIcon />}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Entregas"
                to="/shipping"
                icon={<LoyaltyIcon />}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
              <Item
                title="Publicador"
                to="/publisher/shop"
                icon={<PublicIcon />}
                selected={selected}
                setSelected={setSelected}
                isCollapsed={isCollapsed}
              />
            </Box>
            <Item
              title="Cerrar Sesión"
              to="/"
              onClick={handlelogOutClick}
              icon={<ExitToAppIcon />}
              selected={selected}
              setSelected={setSelected}
              isCollapsed={isCollapsed}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineChart = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const data = {
        labels: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        datasets: [
            {
                label: 'Valor de Venta',
                data: [65, 59, 80, 81, 56, 55, 40, 45, 60, 70, 75, 90],
                fill: false,
                backgroundColor: '#0064FB',
                borderColor: '#0064FB',
                tension: 0.4,
            },
            {
                label: 'Costo',
                data: [50, 48, 70, 72, 46, 45, 30, 35, 50, 60, 65, 80],
                fill: false,
                backgroundColor: '#1ECDF8',
                borderColor: '#1ECDF8',
                tension: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: colors.primary[100], // Color del texto de la leyenda
                    font: {
                        size: 14, // Tamaño de fuente de la leyenda
                    }
                },
            },
            title: {
                display: false,
                text: 'Gráfica de Líneas - Valor de Venta vs Costo',
                color: colors.primary[100] // Color del texto del título
            },
        },
        scales: {
            x: {
                ticks: {
                    color: colors.primary[100], // Cambiar el color de las etiquetas en el eje X
                },
                grid: {
                    color: colors.primary[100] // Cambiar el color de la cuadrícula en el eje X
                },
            },
            y: {
                ticks: {
                    color: colors.primary[100], // Cambiar el color de las etiquetas en el eje Y
                },
                grid: {
                    display:false,
                    color: colors.primary[500] // Cambiar el color de la cuadrícula en el eje Y
                },
            },
        },
    };

    return <Line data={data} options={options} />;
};

export default LineChart;

import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import ContentComponent from '../../components/Settings/ContentComponent/ContentComponent';
import { useParams } from 'react-router-dom';
import BarComponent from '../../components/Settings/BarComponent/BarComponent';
import ProfileComponent from '../../components/Settings/ProfileComponent/ProfileComponent';
import { useSelector } from 'react-redux';
import UpdateProfile from '../../components/Settings/ProfileSettings/UpdateProfile';

const Settings = () => {
    const { activepage } = useParams();
    const user = useSelector((state) => state.User);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h3" sx={{ fontWeight: "700", marginLeft: { xs: "15px", md: "0px" } }}>Perfil</Typography>
                                <Typography variant="h5" sx={{ marginRight: "10px", marginTop: "5px", marginBottom: "25px", marginLeft: { xs: "15px", md: "0px" } }}>Bienvenido a la Página de Configuración</Typography>
                                <ProfileComponent name={user?.name} image={"/profile.jpg"} />
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                <BarComponent activepage={activepage} />
                            </Grid>

                            <Grid item xs={12}>
                                <ContentComponent activepage={activepage} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Settings;
import React, { useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import Box from '@mui/material/Box';
import { Grid, Skeleton, Typography } from '@mui/material';
import Card from '../../Global/Card/Card';
import SearchIcon from "@mui/icons-material/Search";
import { listProducts } from '../../../mockdata/Mockdata';

const ShopPublisher = ({ renderCard }) => {
    const products = listProducts
    console.log(products)
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%", borderRadius: "20px", marginTop: { xs: "15px", md: "0px" } }}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                            <Typography variant="h4" sx={{ fontWeight: "600" }}>Tienda Voortex</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display:{xs:"none", lg:"flex"}}}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <SearchIcon sx={{ width: "55%", height: "25px" }} />
                                <Typography variant="h7">Tecnología</Typography>
                                <Typography variant="h7">Accesorios</Typography>
                                <Typography variant="h7">Hogar</Typography>
                            </Box>
                        </Grid>
                        {products.map((product) => (
                            <Grid item xs={6} lg={renderCard} key={product.sku}>
                                <Card
                                    key={product.sku}
                                    images={product.image}
                                    title={product.title}
                                    amazonPrice={product.price}
                                    rating={"5"}
                                    link={product.short_url}
                                    buttonText={"Publicar"} 
                                    actionType="publish" 
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default ShopPublisher;

import Box from "@mui/material/Box";
import { Grid, TextField, Button, Typography, Tabs, Tab, Autocomplete, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";

const UpdateProducts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        bgcolor: colors.primary[400],
        borderRadius: "15px",
        padding: "15px",
        color: colors.primary[100]
      }}
    >
      <Box
        sx={{
          padding: { xs: "5px", md: "20px" },
          width: "95%",
          borderRadius: "20px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "bold",
            color: colors.primary[100],
          }}
        >
          Centro especializado de soporte
        </Typography>

        <Typography
          variant="h4"
          sx={{ marginBottom: "20px", textAlign: "center", color: colors.primary[100] }}
        >
          ¿Necesitas ayuda? Radica un ticket de soporte, en breve un agente de soporte resolverá todas tus inquietudes.
        </Typography>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor= {colors.primary[100]}
          sx={{ marginBottom: "20px" }}
        >
          <Tab label="Ayuda y soporte" />
          <Tab label="Buscar artículos" />
        </Tabs>

        <Box sx={{ width: "100%", minHeight: "400px", display: "flex", flexDirection: "column" }}>
          {activeTab === 0 && (
            <Grid container spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Solicitado Por"
                  required
                  variant="outlined"
                  InputLabelProps={{ style: { color: colors.primary[100] } }}
                  InputProps={{ style: { color: colors.primary[100] } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Asunto"
                  required
                  variant="outlined"
                  InputLabelProps={{ style: { color: colors.primary[100] } }}
                  InputProps={{ style: { color: colors.primary[100] } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descripción"
                  multiline
                  rows={5}
                  variant="outlined"
                  InputLabelProps={{ style: { color: colors.primary[100] } }}
                  InputProps={{ style: { color: colors.primary[100] } }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel style={{ color: "white" }}>Categoría</InputLabel>
                  <Select variant="outlined" defaultValue="" sx={{ color: colors.primary[100] }}>
                    <MenuItem value="Cliente demo">Cliente demo</MenuItem>
                    <MenuItem value="Incidencia técnica">Incidencia técnica</MenuItem>
                    <MenuItem value="Información sobre pagos">
                      Información sobre pagos
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ textTransform: "none" }}
                >
                  Adjuntar archivo
                  <input type="file" hidden />
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ width: "100%", borderRadius: "10px", background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          )}

          {activeTab === 1 && (
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Autocomplete
                options={[]}
                noOptionsText="Artículos no encontrados"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Buscar..."
                    variant="outlined"
                    InputLabelProps={{ style: { color: colors.primary[100]} }}
                    InputProps={{ ...params.InputProps, style: { color: colors.primary[100] } }}
                  />
                )}
              />
            </Box>
          )}
        </Box>

      </Box>
    </Box>
  );
};

export default UpdateProducts;

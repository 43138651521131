import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./views/global/Topbar";
import Sidebar from "./views/global/Sidebar";
import Home from "./views/home/Home";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Publisher from "./views/publisher/Publisher";
import Settings from "./views/settings/Settings";
import Shop from "./views/shop/Shop";
import Shipping from "./views/shipping/Shipping";
import Results from "./views/results/Results";
import Login from "./views/login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const location = useLocation();
  const path = location?.pathname

  const handleSidebarToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const sidebarWidth = isCollapsed ? "80px" : "250px";

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          localStorage.setItem("id_token", token);
          localStorage.setItem("userEmail", user?.email);
        } catch (error) {
          console.error("Error obteniendo el token:", error);
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, getAccessTokenSilently, user?.email]);


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ display: 'flex', minHeight: '100vh' }}>
          {path !== '/' ? (
            <>
              {isSidebar && (
                <Box
                  className="sidebar"
                  sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: sidebarWidth,
                    transition: 'width 0.3s',
                    zIndex: 1000,
                    display: { xs: "none", md: "flex" }
                  }}
                >
                  <Sidebar isCollapsed={isCollapsed} handleSidebarToggle={handleSidebarToggle} />
                </Box>

              )}
            </>
          ) : (<></>)}
          <Box
            className="content"
            sx={{
              marginLeft: path === '/' ? '0px' : { xs: "0px", md: sidebarWidth },
              flexGrow: 1,
              transition: 'margin-left 0.3s',
            }}
          >
            {path !== '/' ? (
              <>
                <div
                  className="topbar"
                >
                  <Topbar setIsSidebar={setIsSidebar} />
                </div>
              </>
            ) : (<></>)}
            <div
              className="main-content"
            >
              <Routes>
                <Route
                  path="/"
                  element={isAuthenticated ? <Navigate to="/home" /> : <Login />}
                />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Login />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/publisher/:activepage" element={<Publisher />} />
                  <Route path="/settings/:activepage" element={<Settings />} />
                  <Route path="/shop/:activepage" element={<Shop />} />
                  <Route path="/shipping" element={<Shipping />} />
                  <Route path="/results" element={<Results />} />
                </Route>
              </Routes>

            </div>
          </Box>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

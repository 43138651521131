import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { tokens } from "../../../theme";
import BoxButton from "../../Global/BoxButton/BoxButton";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import ChatIcon from '@mui/icons-material/Chat';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockClockIcon from '@mui/icons-material/LockClock';

const BarComponent = ({ activepage }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const handleSelectChange = (event) => {
        navigate(`/shop/${event.target.value}`); // Redirige a la página seleccionada
    };
    return (
        <Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, flexDirection:"column" }}>
                <Select
                    value={activepage}
                    onChange={handleSelectChange}
                    variant="outlined"
                    sx={{ width: '100%', display: "flex", flexDirection: "row",  backgroundColor: colors.primary[980]}}
                    size="medium"
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: colors.primary[980],
                            },
                        },
                    }}
                >
                    <MenuItem value="edit">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <AppRegistrationIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Edita tus Publicaciones
                        </Box>
                    </MenuItem>
                    <MenuItem value="support">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <SystemSecurityUpdateGoodIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Soporte Voortex
                        </Box>
                    </MenuItem>
                    <MenuItem value="talk">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <ChatIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Habla con Clientes
                        </Box>
                    </MenuItem>
                    <MenuItem value="blocks">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <LockClockIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Infracciones y Bloqueos
                        </Box>
                    </MenuItem>
                </Select>
            </Box>
            <Grid container spacing={2} sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "edit" ? true : false}
                        icon={<AppRegistrationIcon />}
                        title="Edita tus Publicaciones"
                        buttonText="Ajustar"
                        onClick="/shop/edit"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "support" ? true : false}
                        icon={<SystemSecurityUpdateGoodIcon />}
                        title="Soporte Voortex"
                        buttonText="Ajustar"
                        onClick="/shop/support"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "talk" ? true : false}
                        icon={<ChatIcon />}
                        title="Habla con Clientes"
                        buttonText="Ajustar"
                        onClick="/shop/talk"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "blocks" ? true : false}
                        icon={<LockClockIcon />}
                        title="Infracciones y Bloqueos"
                        buttonText="Ajustar"
                        onClick="/shop/blocks"
                        status="develop"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BarComponent;

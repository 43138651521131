import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import Questions from './Questions';

import { questions } from '../../../mockdata/Mockdata';

const TalkClients = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "15px", padding: "5px" }}>
      <Box sx={{ padding: { xs: "0px", md: "10px" }, width: "100%", borderRadius: "20px" }}>
        <Typography variant="h3" sx={{ fontWeight: "700", color: colors.primary[100] }}>Habla con tus clientes</Typography>
        <Typography variant="h5" sx={{ marginRight: "10px", marginTop: "5px", marginBottom: "20px", color: colors.primary[100] }}>
          En este espacio podrás responder las dudas de los usuarios
        </Typography>

        {/* Dividir las preguntas en prospectos y clientes */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginRight: "10px", marginTop: "5px", marginBottom: "20px", color: colors.primary[100] }}>
              Preguntas Prospectos
            </Typography>
            {questions.filter(q => !q.isCustomer).map((question, index) => (
              <Questions
                key={index}
                question={question.question}
                image={question.image}
                title={question.title}
                price={question.price}
                user={question.user}
                isCustomer={question.isCustomer}
              />
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginRight: "10px", marginTop: "5px", marginBottom: "20px", color: colors.primary[100] }}>
              Preguntas Clientes
            </Typography>
            {questions.filter(q => q.isCustomer).map((question, index) => (
              <Questions
                key={index}
                question={question.question}
                image={question.image}
                title={question.title}
                price={question.price}
                user={question.user}
                isCustomer={question.isCustomer}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TalkClients;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Banner = () => {

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '250px',
        borderRadius:"20px",
        backgroundImage: 'url(/discover.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center',
        overflow: 'hidden',
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 255, 0.15)', // Filtro azul con opacidad
          zIndex: 1
        }}
      />
      <Box sx={{ zIndex: 2, textAlign:"left", justifySelf:"flex-start", width:"90%", marginTop:"10px", marginBottom:"10px"}}>
        <Typography variant="h2" component="h1">
          Dropshipping 
        </Typography>
        <Typography variant="h2" component="h1" gutterBottom>
        en MercadoLibre
        </Typography>
        <Typography variant="h5" component="p" gutterBottom>
          Gestión digital de tus cuentas y productos en una sola plataforma.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
          <Button variant="contained" sx={{width:"100%", mt:"15px", borderRadius:"10px", background: "linear-gradient(to right, #0064FB, #1ECDF8)", height:"50px"}}>
            Explorar
          </Button>
          <Button variant="contained" sx={{width:"100%", mt:"15px", borderRadius:"10px", background: "linear-gradient(to right, #990000, #FF0000)"}}>
            Ver tutorial
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;

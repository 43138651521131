import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const DonutChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const data = {
    labels: ['Ventas', 'Utilidad'],
    datasets: [
      {
        label: 'Utilidad Generada',
        data: [300, 200],
        backgroundColor: [
          '#FFFFFF',
          '#0064FB',
        ],
        borderColor: [
          '#0064FB',
          '#0064FB',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: colors.primary[100],
          font: {
            size: 14, // Tamaño de fuente de la leyenda
          },
        },
      },
      title: {
        display: false,
        text: 'Gráfico de Dona - Utilidad Generada',
        color: '#333', // Color del texto del título
        font: {
          size: 18, // Tamaño de fuente del título
        },
      },
      tooltip: {
        bodyColor: '#FFFFFF',
        titleColor: '#FFFFFF',
      },
      
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default DonutChart;

import axios from 'axios';
import Swal from 'sweetalert2';
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_PRODUCTS = "GET_PRODUCTS";
const baseURL = process.env.REACT_APP_API_URL;

export const getUserData = () => {
    return async (dispatch) => {
        try {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: baseURL + "/users/sellers",
            };

            const response = await axios.request(config);
            dispatch({ type: GET_USERS, payload: response.data });
            return response.data;
        } catch (error) {
            return false;
        }
    };
};

export const getProducts = () => {
    return async (dispatch) => {
        try {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: baseURL + "/products",
            };

            const response = await axios.request(config);
            dispatch({ type: GET_PRODUCTS, payload: response.data });
            return response.data;
        } catch (error) {
            return false;
        }
    };
};

export const getUserByID = (userId) => {
    return async (dispatch) => {
        try {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: baseURL + `/users?userId=${userId}`,
            };

            const response = await axios.request(config);
            dispatch({ type: GET_USER, payload: response.data });
            return response.data;
        } catch (error) {
            return false;
        }
    };
};

export const createNewClient = (userData) => {
    return async (dispatch) => {
        try {
                await axios.post(baseURL + "/users", userData);
                Swal.fire({
                    icon: 'success',
                    title: '¡Cliente Creado!',
                    text: 'El cliente se ha creado exitosamente.',
                });
        } catch (error) {
        }
    };
};

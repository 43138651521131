import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography, TextField, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const DescriptionSettings = ({ description }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [localDescription, setLocalDescription] = useState(description);

    const handleDescriptionChange = (event) => {
        setLocalDescription(event.target.value);
    };

    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: "35px",
                borderRadius: "15px",
                color: "white",
                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' , padding: '10px'}}>
                    <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px", color: colors.primary[100] }}>Modificar descripción de la tienda</Typography>
                </Grid>

                <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: "13px" }}>
                    <TextField
                        value={localDescription}
                        onChange={handleDescriptionChange}
                        placeholder="Digita la descripción de la tienda"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={10} 
                        InputProps={{
                            sx: {
                                color: colors.primary[100],
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                padding: '14px',
                            },
                        }}
                        sx={{ background: 'transparent', borderRadius: '10px' }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={2} sx={{ alignItems: "center" }}>
                    <Button variant="contained" color="primary" fullWidth sx={{ marginTop: '10px', textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DescriptionSettings;

import Box from '@mui/material/Box';
import { Grid, useTheme, Typography, TextField, Button } from '@mui/material';
import { tokens } from '../../../theme';
import { useState } from 'react';

const TaxesSettings = ({ taxes }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [localTaxes, setLocalTaxes] = useState(taxes);

    const handleTaxesChange = (event) => {
        setLocalTaxes(event.target.value);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px"}}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px", color: colors.primary[100] }}>Impuesto Amazon</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginTop:'10px' }}>
                            <TextField
                                value={localTaxes}
                                onChange={handleTaxesChange}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    sx: {
                                        color: colors.primary[100],
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        height: '25px',
                                        padding: '0 14px',
                                    },
                                }}
                                sx={{ background: 'transparent', borderRadius: '10px' }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={2} sx={{ alignItems: "center" }}>
                            <Button variant="contained" color="primary" fullWidth sx={{ marginTop: '25px', textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default TaxesSettings;

import Box from '@mui/material/Box';
import { Grid, Skeleton, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const ProductBlocks = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
        <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Skeleton variant='rectangular' sx={{height:"40px", borderRadius:"15px", marginBottom:"15px"}}/>
              <Skeleton variant='rectangular' sx={{height:"300px", borderRadius:"15px", marginBottom:"10px"}}/>
              <Skeleton variant='rectangular' sx={{height:"200px", borderRadius:"15px", marginBottom:"10px"}}/>
              <Skeleton variant='rectangular' sx={{height:"300px", borderRadius:"15px", marginBottom:"10px"}}/>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
};

export default ProductBlocks;
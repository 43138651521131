import Box from '@mui/material/Box';
import { Grid, IconButton, Typography, useTheme, Modal, Button } from '@mui/material';
import LineChart from '../../components/Charts/LineChart';
import { tokens } from '../../theme';
import DonutChart from '../../components/Charts/DonutChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import { sampleRows } from '../../mockdata/Mockdata';
import { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const Results = () => {
    const [year, setYear] = useState(2024);
    const [month, setMonth] = useState("Enero");
    const [filteredRows, setFilteredRows] = useState(sampleRows);

    const [isMonthModalOpen, setMonthModalOpen] = useState(false);
    const [isYearModalOpen, setYearModalOpen] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const filterByYear = (selectedYear) => {
        const filtered = sampleRows.filter(row => new Date(row.date).getFullYear() === selectedYear);
        setFilteredRows(filtered);
        setYear(selectedYear);
        setYearModalOpen(false);
    };

    const filterByMonth = (selectedMonth) => {
        const monthMapping = {
            Enero: 0, Febrero: 1, Marzo: 2, Abril: 3, Mayo: 4, Junio: 5,
            Julio: 6, Agosto: 7, Septiembre: 8, Octubre: 9, Noviembre: 10, Diciembre: 11
        };
        const filtered = sampleRows.filter(row => new Date(row.date).getMonth() === monthMapping[selectedMonth]);
        setFilteredRows(filtered);
        setMonth(selectedMonth);
        setMonthModalOpen(false);
    };

    const userColumns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'date', headerName: 'Fecha', flex: 1 },
        { field: 'title', headerName: 'Título', flex: 1 },
        { field: 'id_venta', headerName: 'ID Venta', flex: 1 },
        { field: 'id_meli', headerName: 'ID Meli', flex: 1 },
        { field: 'sku', headerName: 'SKU', flex: 1 },
        { field: 'qty', headerName: 'Cantidad', flex: 1 },
        { field: 'qtyproduct', headerName: 'Valor Producto', flex: 1 },
        { field: 'qtyMeli', headerName: 'Comisión Meli', flex: 1 },
        { field: 'qtyAmazon', headerName: 'Impuesto Amazon', flex: 1 },
        { field: 'qtySale', headerName: 'Impuesto Venta', flex: 1 },
        { field: 'qtyNational', headerName: 'Impuesto Nacional', flex: 1 },
        { field: 'shipping', headerName: 'Mercado Envíos', flex: 1 },
        { field: 'shippingInternational', headerName: 'Envío Internacional', flex: 1 },
        { field: 'utility', headerName: 'Utilidad', flex: 1 },
    ];

    const renderModal = (type) => (
        <Modal
            open={type === 'month' ? isMonthModalOpen : isYearModalOpen}
            onClose={() => (type === 'month' ? setMonthModalOpen(false) : setYearModalOpen(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: colors.primary[980],
                boxShadow: 24,
                p: 4,
                borderRadius: '10px',
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
                    Seleccione {type === 'month' ? 'un Mes' : 'un Año'}
                </Typography>
                {type === 'month' ? (
                    <Box>
                        {["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
                            .map((m) => (
                                <Button
                                    key={m}
                                    onClick={() => filterByMonth(m)}
                                    sx={{ display: 'block', mb: 1, textAlign: 'left', width: '100%', color: colors.primary[100] }}
                                >
                                    {m}
                                </Button>
                            ))}
                    </Box>
                ) : (
                    <Box>
                        {[2024, 2023, 2022, 2021, 2020].map((y) => (
                            <Button
                                key={y}
                                onClick={() => filterByYear(y)}
                                sx={{ display: 'block', mb: 1, textAlign: 'left', width: '100%', color: colors.primary[100] }}
                            >
                                {y}
                            </Button>
                        ))}
                    </Box>
                )}
            </Box>
        </Modal>
    );

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ padding: { xs: "10px", md: "20px" }, width: "95%", maxWidth: "1280px", borderRadius: "20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={5}>
                        <Typography variant="h4" sx={{ fontWeight: "700", marginBottom: "15px" }}>Ventas / Compras</Typography>
                        <Box sx={{
                            backgroundColor: colors.primary[400],
                            padding: "20px",
                            borderRadius: "15px",
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}>
                            <LineChart />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="h4" sx={{ fontWeight: "700", marginBottom: "15px" }}>Utilidad Generada</Typography>
                        <Box sx={{
                            backgroundColor: colors.primary[400],
                            padding: "20px",
                            borderRadius: "15px",
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        }}>
                            <DonutChart />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Typography variant="h4" sx={{ fontWeight: "700", marginBottom: "15px" }}>Movimientos</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                borderRadius: "15px",
                                background: colors.primary[980],
                                minHeight: "92px",
                                width: "100%",
                                marginBottom: "10px",
                                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            }}
                        >
                            <Box sx={{ padding: "10px", display: "flex", justifyContent: "center", marginLeft: "10px", bgcolor: "#0064FB", borderRadius: "50%", marginRight:"15px" }}>
                                <AccountBalanceWalletIcon />
                            </Box>
                            <Box sx={{ width: "50%"}}>
                                <Typography variant="h3" sx={{ fontWeight: "600" }}>103M</Typography>
                                <Typography variant="h6" >Utilidad</Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", alignSelf: "flex-start", width: "15%" }}>
                                <Typography variant="body1" sx={{ color: '#2ECC71', fontWeight: 'bold' }}>
                                    40%
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                borderRadius: "15px",
                                background: colors.primary[980],
                                minHeight: "92px",
                                width: "100%",
                                marginBottom: "10px",
                                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            }}
                        >
                            <Box sx={{ padding: "10px", display: "flex", justifyContent: "center", marginLeft: "10px", bgcolor: "#FF0000", borderRadius: "50%", marginRight:"15px" }}>
                                <CalendarMonthIcon />
                            </Box>
                            <Box sx={{ width: "50%"}}>
                                <Typography variant="h3" sx={{ fontWeight: "600" }}>{month}</Typography>
                                <Typography variant="h6" >Mes</Typography>
                            </Box>
                            <IconButton
                                color="primary"
                                onClick={() => setMonthModalOpen(true)}
                                sx={{
                                    width: "40px",
                                    borderRadius: "10px",
                                    background: "linear-gradient(to right, #0064FB, #1ECDF8)",
                                    height: "40px",
                                }}
                            >
                                <EditIcon sx={{ color: '#FFFFFF' }} />
                            </IconButton>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: 2,
                                borderRadius: "15px",
                                background: colors.primary[980],
                                minHeight: "92px",
                                width: "100%",
                                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                            }}
                        >
                            <Box sx={{ padding: "10px", display: "flex", justifyContent: "center", marginLeft: "10px", bgcolor: "#2ECC71", borderRadius: "50%", marginRight:"15px" }}>
                                <CalendarTodayIcon />
                            </Box>
                            <Box sx={{ width: "50%"}}>
                                <Typography variant="h3" sx={{ fontWeight: "600" }}>{year}</Typography>
                                <Typography variant="h6" >Año</Typography>
                            </Box>
                            <IconButton
                                color="primary"
                                onClick={() => setYearModalOpen(true)}
                                sx={{
                                    width: "40px",
                                    borderRadius: "10px",
                                    background: "linear-gradient(to right, #0064FB, #1ECDF8)",
                                    height: "40px",
                                }}
                            >
                                <EditIcon sx={{ color: '#FFFFFF' }} />
                            </IconButton>

                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            sx={{
                                backgroundColor: "#ffffff",
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .name-column--cell": {
                                    color: colors.greenAccent[300],
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: colors.blueAccent[800],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: colors.primary[400],
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: colors.primary[500],
                                },
                                "& .MuiCheckbox-root": {
                                    color: `${colors.greenAccent[200]} !important`,
                                },
                                "& .MuiDataGrid-toolbarContainer": {
                                    bgcolor: `${colors.primary[500]}`,
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `#ffffff !important`,
                                },
                                height:"500px"
                            }}
                        >
                            <DataGrid
                                rows={filteredRows}
                                columns={userColumns}
                                pageSize={5}
                                slots={{ toolbar: GridToolbar }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {renderModal('month')}
                {renderModal('year')}
            </Box>
        </Box >
    );
};

export default Results;

import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, MenuItem, Select, useTheme } from '@mui/material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import TableChartIcon from '@mui/icons-material/TableChart';
import { tokens } from "../../../theme";
import BoxButton from "../../Global/BoxButton/BoxButton";

const BarComponent = ({ activepage }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const handleSelectChange = (event) => {
        navigate(`/publisher/${event.target.value}`); // Redirige a la página seleccionada
    };
    return (
        <Box>
            <Box sx={{ backgroundColor: colors.primary[980], display: { xs: "flex", md: "none" } }}>
                <Select
                    value={activepage}
                    onChange={handleSelectChange}
                    variant="outlined"
                    sx={{ width: '100%', display: "flex", flexDirection: "row" }}
                    size="medium"
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: colors.primary[980],
                            },
                        },
                    }}
                >
                    <MenuItem value="shop">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <ShoppingBagIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Publica Desde Nuestra Tienda
                        </Box>
                    </MenuItem>
                    <MenuItem value="search">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <ScreenSearchDesktopIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Publica Con El Buscador
                        </Box>
                    </MenuItem>
                    <MenuItem value="unit">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <LooksOneIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Publica 1 Solo Producto
                        </Box>
                    </MenuItem>
                    <MenuItem value="csv">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <TableChartIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Publica Con CSV
                        </Box>
                    </MenuItem>
                </Select>
            </Box>
            <Grid container spacing={2} sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "shop" ? true : false}
                        icon={<ShoppingBagIcon />}
                        title="Publica Desde Nuestra Tienda"
                        buttonText="Publicar"
                        onClick="/publisher/shop"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "search" ? true : false}
                        icon={<ScreenSearchDesktopIcon />}
                        title="Publica Con El Buscador"
                        buttonText="Publicar"
                        onClick="/publisher/search"
                        status="develop"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "unit" ? true : false}
                        icon={<LooksOneIcon />}
                        title="Publica 1 Solo Producto"
                        buttonText="Publicar"
                        onClick="/publisher/unit"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "csv" ? true : false}
                        icon={<TableChartIcon />}
                        title="Publica Con CSV"
                        buttonText="Publicar"
                        onClick="/publisher/csv"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BarComponent;

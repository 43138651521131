import { Box, Grid, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import { useState } from 'react';

const ImportSettings = ({ minImport, maxImport, Import }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [localMinImport, setLocalMinImport] = useState(minImport);
    const [localMaxImport, setLocalMaxImport] = useState(maxImport);
    const [localImport, setLocalImport] = useState(Import);
    const [openDialog, setOpenDialog] = useState(false);
    const [columns, setColumns] = useState([]);

    const handleChange = (setter) => (event) => setter(event.target.value);

    const handleAddColumn = () => setColumns([...columns, { min: '', max: '', Import: '' }]);
    const handleRemoveColumn = (index) => setColumns(columns.filter((_, i) => i !== index));

    const handleChangeColumn = (index, field) => (event) => {
        const newColumns = [...columns];
        newColumns[index][field] = event.target.value;
        setColumns(newColumns);
    };

    const renderTextField = (label, value, onChange) => (
        <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px", color: colors.primary[100] }}>
                {label}
            </Typography>
            <TextField
                value={value}
                onChange={onChange}
                variant="outlined"
                fullWidth
                InputProps={{
                    sx: {
                        color: colors.primary[100],
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        height: '25px',
                        padding: '0 14px',
                    },
                }}
                sx={{ background: colors.primary[980], borderRadius: '10px', padding: '10px' }}
            />
        </Grid>
    );

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%", borderRadius: "10px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px", color: colors.primary[100] }}>Modificar importación</Typography>

                        <Grid container spacing={2}>
                            {renderTextField("Rango mínimo", localMinImport, handleChange(setLocalMinImport))}
                            {renderTextField("Rango máximo", localMaxImport, handleChange(setLocalMaxImport))}
                            {renderTextField("Valor USD", localImport, handleChange(setLocalImport))}
                        </Grid>

                        <Grid container spacing={4}>
                            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: '35px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: '35px' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #FF5733, #FF0000)" }}
                                    onClick={() => setOpenDialog(true)}
                                >
                                    Agregar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Dialog sx={{ '& .MuiDialog-paper': { minWidth: '50%', backgroundColor: colors.primary[500], } }}
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Bienvenido a la importación</DialogTitle>
                <DialogContent dividers>
                    <Grid container justifyContent="center">
                        {columns.map((column, index) => (
                            <Grid container spacing={1} key={index} sx={{ padding: '10px', background: colors.primary[400], borderRadius: '10px', margin: '5px' }}>
                                {renderTextField("Rango mínimo", column.min, handleChangeColumn(index, 'min'))}
                                {renderTextField("Rango máximo", column.max, handleChangeColumn(index, 'max'))}
                                {renderTextField("Valor USD", column.Import, handleChangeColumn(index, 'Import'))}
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleRemoveColumn(index)}
                                    sx={{ marginTop: '20px', textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', fontSize: '16px', width: '100%' }}
                                >
                                    Eliminar
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenDialog(false)}
                                sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', width: '80%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}
                            >
                                Guardar
                            </Button>
                        </Grid>
                        <Grid item xs={4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleAddColumn}
                                sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', width: '80%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}
                            >
                                Agregar
                            </Button>
                        </Grid>
                        <Grid item xs={4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpenDialog(false)}
                                sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', width: '80%', fontSize: '16px', background: "linear-gradient(to right, #FF5733, #FF0000)" }}
                            >
                                Salir
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ImportSettings;

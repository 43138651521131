import Box from '@mui/material/Box';
import { Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const UnitPublisher = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", bgcolor:colors.primary[400], borderRadius:"15px", padding:"15px"}}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%"}}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{ fontWeight: "700" }}>Publicador de Productos Unitario</Typography>
                                <Typography variant="h5" sx={{ marginRight: "10px", marginTop: "5px" }}>Por favor escribe el código único de tu producto de Amazon</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="filled-basic" label="Código de Producto" variant="filled" fullWidth sx={{ mt: "10px" }} />
                                <Typography variant="h5" sx={{ marginTop: "10px", fontWeight: "600", textAlign: "center" }}>Verifica que tu producto cumpla con las condiciones de MercadoLibre</Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <Button variant="contained" sx={{ width: "100%", mt: "15px", borderRadius: "10px", background: "linear-gradient(to right, #990000, #FF0000)", height: "50px" }}>
                                Limpiar Buscador
                            </Button>
                            </Grid>
                            <Grid item xs={6}>
                            <Button variant="contained" sx={{ width: "100%", mt: "15px", borderRadius: "10px", background: "linear-gradient(to right, #0064FB, #1ECDF8)", height: "50px" }}>
                                Publicar
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default UnitPublisher;
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import ProfileSettings from '../ProfileSettings/ProfileSettings';
import PriceSettings from '../PriceSettings/PriceSettings';
import ShippingSettings from '../ShippingSettings/ShippingSettings';
import AppSettings from '../AppSettings/AppSettings';
import UpdateProfile from '../ProfileSettings/UpdateProfile';

const ContentComponent = ({ activepage }) => {

  return (
    <Box>
      <Grid container spacing={2} sx={{ padding: { xs: "10px", md: "20px" } }} justifyContent={"space-around"}>
        <Grid item xs={12}>
          {activepage === 'price' && <PriceSettings />}
          {activepage === 'shipping' && <ShippingSettings />}
          {activepage === 'app' && <AppSettings />}
          {activepage === 'profile' && <ProfileSettings renderCard={3} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentComponent;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';

const CardTop = ({ name, total, image }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: colors.primary[600],
                borderRadius: '10px',
                padding: '16px',
                marginBottom: '8px',
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={image}
                    alt="David"
                    sx={{ marginRight: '16px' }}>P</Avatar>
                <Box sx={{display:"flex", justifyContent:"flex-start", flexDirection:"column", alignItems:"flex-start"}}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        ${total}
                    </Typography>
                </Box>
            </Box>
            <IconButton color="primary" sx={{ width: "40px", borderRadius: "10px", background: "linear-gradient(to right, #0064FB, #1ECDF8)", height: "40px" }}>
                <FavoriteIcon sx={{color:'#FFFFFF'}}/>
            </IconButton>
        </Box>
    );
};

export default CardTop;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography, TextField, IconButton, InputAdornment, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UpdateProfile = ({ email, password }) => {
  const [localEmail, setLocalEmail] = useState(email);
  const [localPassword, setLocalPassword] = useState(password);
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleEmailChange = (event) => {
    setLocalEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setLocalPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        background: colors.primary[400],
        padding: { xs: "12px", md: "30px" },
        width: "100%",
        borderRadius: "15px",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        marginBottom: "20px",
        minHeight: "300px"  // De esta forma hago que tengan un tamaño minimo y que tengan el mismo tamaño.
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px" }}>Correo</Typography>
        </Grid>

        <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: "10px" }}>
          <TextField
            value={localEmail}
            onChange={handleEmailChange}
            variant="outlined"
            fullWidth
            InputProps={{
              sx: {
                color: colors.primary[100],
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                height: '25px', // Ajuste de la altura
                padding: '0 14px',
              },
            }}
            sx={{ background: 'transparent', borderRadius: '10px' }}
          />
        </Grid>

        <Grid item xs={6} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px" }}>Contraseña</Typography>
        </Grid>

        <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: "10px" }}>
          <TextField
            type={showPassword ? 'text' : 'password'}
            value={localPassword}
            onChange={handlePasswordChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                color: colors.primary[100],
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                height: '25px', 
                padding: '0 14px',
              },
            }}
            sx={{ background: 'transparent', borderRadius: '10px' }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}sx={{ alignItems: "center" }}>
          <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
            Guardar
          </Button>
        </Grid>            
      </Grid>
    </Box>
  );
};

export default UpdateProfile;

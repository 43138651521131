import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../../theme';

const AnswerFastSettings = ({ answers }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setRows(answers);
    }, [answers]);

    const userColumns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'title', headerName: 'Título', width: 380 },
        { field: 'message', headerName: 'Mensaje', width: 480 },
    ];

    const handleRowDoubleClick = (params) => {
        setSelectedRow(params.row);
        setOpenDialog(true);
    };

    const handleDelete = () => {
        setRows(rows.filter((row) => row.id !== selectedRow.id));
        setOpenDialog(false);
        setSelectedRow(null);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box sx={{ width: "100%", borderRadius: "20px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            m="20px 0 0 0"
                            height="65vh"
                            width="100%"
                            sx={{
                                backgroundColor: "#ffffff",
                                "& .MuiDataGrid-root": {
                                    border: "none",
                                },
                                "& .MuiDataGrid-cell": {
                                    borderBottom: "none",
                                },
                                "& .name-column--cell": {
                                    color: colors.greenAccent[300],
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: colors.blueAccent[800],
                                    borderBottom: "none",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    backgroundColor: colors.primary[400],
                                },
                                "& .MuiDataGrid-footerContainer": {
                                    borderTop: "none",
                                    backgroundColor: colors.blueAccent[800],
                                },
                                "& .MuiCheckbox-root": {
                                    color: `${colors.greenAccent[200]} !important`,
                                },
                                "& .MuiDataGrid-toolbarContainer": {
                                    bgcolor: `${colors.primary[980]}`
                                },
                                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                    color: `${colors.primary[100]} !important`,
                                },
                            }}
                        >
                            <DataGrid
                                rows={rows} // Utilizando el estado rows para manejar la eliminación
                                columns={userColumns}
                                pageSize={10}
                                slots={{ toolbar: GridToolbar }}
                                getRowId={(row) => row.id}
                                checkboxSelection
                                onRowDoubleClick={handleRowDoubleClick}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>CONFIRMAR ELIMINACION</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Esta seguro de borrar este registro?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AnswerFastSettings;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography, TextField, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const WarrantySettings = ({ Warranty }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [localWarranty, setLocalWarranty] = useState(Warranty);

    const handleWarrantyChange = (event) => {
        setLocalWarranty(event.target.value);
    };

    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: "35px",
                borderRadius: "15px",
                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                color: "white",
                
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px",color: colors.primary[100] }}>
                        Modificar garantía
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: '13px' }}>
                    <TextField
                        value={localWarranty}
                        onChange={handleWarrantyChange}
                        placeholder="Digita la cantidad de días de garantía"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            sx: {
                                color: colors.primary[100],
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                height: '25px',
                                padding: '0 14px',
                            },
                        }}
                        sx={{ background: 'transparent', borderRadius: '10px' }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ marginTop: '10px', textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WarrantySettings;

import Box from '@mui/material/Box';
import { Grid, useTheme, Typography, TextField, Switch , Button} from '@mui/material';
import { tokens } from '../../../theme';
import { useState } from 'react';

const CostSettings = ({ cost, userCost }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [localCost, setLocalCost] = useState(cost);
    const [localuserCost, setLocaluserCost] = useState(userCost);
    const [freeShipping, setFreeShipping] = useState(false);

    const handleCostChange = (event) => {
        setLocalCost(event.target.value);
    };

    const handleuserCostChange = (event) => {
        setLocaluserCost(event.target.value);
    };

    const handleFreeShippingChange = (event) => {
        setFreeShipping(event.target.checked);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", bgcolor: colors.primary[400], borderRadius: "15px", padding: "15px" }}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "100%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px", color: colors.primary[100] }}>Modificar costo de envío</Typography>
                        
                        <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px' }}>
                            <TextField
                                value={localCost}
                                onChange={handleCostChange}
                                placeholder='Digita el costo de mercado envíos'
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    sx: {
                                        color: colors.primary[100],
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        height: '25px',
                                        padding: '0 14px',
                                    },
                                }}
                                sx={{ background: 'transparent', borderRadius: '10px' }}
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
                            <Typography variant="h6" sx={{ fontFamily: 'Arial, sans-serif', color: colors.primary[100]}}>Si tus productos tendrán envío gratis, activa la siguiente opción</Typography>
                            <Switch
                                checked={freeShipping}
                                onChange={handleFreeShippingChange}
                                color="secondary"
                            />
                        </Grid>

                        <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginTop: '12px' }}>
                            <TextField
                                value={localuserCost}
                                onChange={handleuserCostChange}
                                placeholder='Digita el costo a acordar con el comprador'
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    sx: {
                                        color: colors.primary[100],
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        height: '25px',
                                        padding: '0 14px',
                                    },
                                }}
                                sx={{ background: 'transparent', borderRadius: '10px' }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={2} sx={{ alignItems: "center" }}>
                            <Button variant="contained" color="primary" fullWidth sx={{ marginTop: '20px', textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }}>
                                Guardar
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CostSettings;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Grid, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { listProducts } from '../../../mockdata/Mockdata';
import Card from '../../Global/Card/Card';

const EditPost = ({ renderCard = 4 }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = useState(false);
  const [productToUpdate, setProductToUpdate] = useState(null);

  const handleUpdate = (product) => {
    setProductToUpdate(product);
    setOpen(true); 
  };

  const handleClose = () => {
    setOpen(false);
    setProductToUpdate(null);
  };

  const handleSave = () => {
    console.log('Guardando producto actualizado:', productToUpdate);
    handleClose();
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px" }}>
      <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontWeight: "700", marginBottom: "15px" }}>
              Productos Disponibles
            </Typography>

            <Grid container spacing={4}>
              {listProducts.map((product) => (
                <Grid item xs={12} sm={6} lg={renderCard} key={product.sku}>
                  <Card
                    key={product.sku}
                    images={product.image}
                    title={product.title}
                    amazonPrice={product.price}
                    rating={"5"}
                    link={product.short_url}
                    buttonText="Modificar"
                    actionType="update" 
                    onUpdate={() => handleUpdate(product)} 
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ backgroundColor: colors.primary[500], color: 'white', fontSize: '24px', paddingTop: "20px" }}>
          Actualizar Producto
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: colors.primary[500], paddingTop: "16px" }}>
          {productToUpdate && (
            <Box sx={{ width: "100%", marginTop: "10px" }}>
              <TextField
                label="Título"
                fullWidth
                value={productToUpdate.title}
                onChange={(e) => setProductToUpdate({ ...productToUpdate, title: e.target.value })}
                sx={{
                  marginBottom: "16px",
                  input: { fontSize: '18px', color: 'white' },
                  backgroundColor: colors.primary[400], 
                  borderRadius: "8px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.primary[400], 
                    },
                    '&:hover fieldset': {
                      borderColor: colors.primary[500], 
                    },
                  }
                }}
                InputLabelProps={{
                  style: { fontSize: '18px', color: 'white' }
                }}
              />
              <TextField
                label="Precio"
                fullWidth
                type="number"
                value={productToUpdate.price}
                onChange={(e) => setProductToUpdate({ ...productToUpdate, price: e.target.value })}
                sx={{
                  marginBottom: "16px",
                  input: { fontSize: '18px', color: 'white' },
                  backgroundColor: colors.primary[400], 
                  borderRadius: "8px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.primary[400], 
                    },
                    '&:hover fieldset': {
                      borderColor: colors.primary[500], 
                    },
                  }
                }}
                InputLabelProps={{
                  style: { fontSize: '18px', color: 'white' }
                }}
              />
              <TextField
                label="Enlace Amazon"
                fullWidth
                value={productToUpdate.short_url}
                onChange={(e) => setProductToUpdate({ ...productToUpdate, short_url: e.target.value })}
                sx={{
                  marginBottom: "16px",
                  input: { fontSize: '18px', color: 'white' },
                  backgroundColor: colors.primary[400], 
                  borderRadius: "8px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.primary[500], 
                    },
                    '&:hover fieldset': {
                      borderColor: colors.primary[500], 
                    },
                  }
                }}
                InputLabelProps={{
                  style: { fontSize: '18px', color: 'white' }
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: colors.primary[500] }}>
          <Button onClick={handleClose} color="primary" sx={{ color: 'white' }}>Cancelar</Button>
          <Button onClick={handleSave} color="primary" sx={{ color: 'white' }}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditPost;

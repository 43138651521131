import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Grid, useTheme, Link, Snackbar, Alert } from '@mui/material';
import { tokens } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@emotion/react';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [isAnimating, setIsAnimating] = useState(false);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading,
        user,
        handleRedirectCallback,
        getAccessTokenSilently,
    } = useAuth0();


    useEffect(() => {
        const handleRedirect = async () => {
            try {
                if (window.location.search.includes('code=')) {
                    console.log('Manejando redireccionamiento...');
                    await handleRedirectCallback();
                    const token = await getAccessTokenSilently();
                    setAccessToken(token);
                }
            } catch (error) {
                console.error('Error manejando el redireccionamiento:', error);
            }
        };
        handleRedirect();
    }, [handleRedirectCallback, getAccessTokenSilently]);

    const handleAuth0Login = async () => {
        if (!isAuthenticated) {
            console.log('Redirigiendo para iniciar sesión...');
            await loginWithRedirect({
                authorizationParams: {
                    prompt: "login", // Fuerza la interacción del usuario
                }
            });
        }
    };

    // Extraer mensaje de error de la URL
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const errorMessage = params.get("error_description");
        if (errorMessage) {
            setSnackbarMessage(errorMessage);
            setSnackbarOpen(true);
        }
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    // Definir la animación de escala
    const loginAnimation = keyframes`
        0% { transform: scale(1); opacity: 1; }
        100% { transform: scale(0.9); opacity: 0; }
    `;

    return (
        <Box sx={{
            height: '100vh', display: 'flex',
            position: "relative",
            flex: 1,
            backgroundImage: { xs: `url('planet.jpg')`, md: `url('fondo.png')` },
            backgroundSize: 'cover',
            backgroundPosition: "center",
            overflow: "hidden"
        }}>
            {isAuthenticated ? (
                <Box
                    sx={{
                        width: { xs: '90%', md: '60%', lg: '25%' },
                        padding: '40px',
                        borderRadius: '15px',
                        backgroundColor: 'rgba(19,19,22,0.90)',
                        boxShadow: 'rgba(91, 223, 237, 0.3) 0px 54px 55px, rgba(91, 223, 237, 0.3) 0px -12px 30px',
                        textAlign: 'center',
                        color: 'white',
                    }}
                >
                    <img
                        src={user.picture}
                        alt={user.name}
                        style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            marginBottom: '20px',
                        }}
                    />
                    <Typography variant="h5">{user.name}</Typography>
                    <Typography variant="body1">{user.email}</Typography>
                    {accessToken && (
                        <>
                            <Typography variant="body2">Token: {accessToken}</Typography>
                            <Typography variant="body2">Correo: {user.email}</Typography>
                        </>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => navigate('/home')}
                        sx={{
                            marginTop: '20px',
                            background: 'linear-gradient(to right, #0064FB, #1ECDF8)',
                            textTransform: 'none',
                        }}
                    >
                        Ir al Dashboard
                    </Button>
                </Box>
            ) : (
                <Box sx={{
                    height: '100vh', display: 'flex',
                    position: "relative",
                    flex: 1,
                    backgroundImage: { xs: `url('planet.jpg')`, md: `url('fondo.png')` },
                    backgroundSize: 'cover',
                    backgroundPosition: "center",
                    overflow: "hidden"
                }}>
                    <Box sx={{
                        display: { xs: "flex", md: "none" },
                        position: 'absolute',
                        width: '90px',
                        top: '130px',
                        left: '10px',
                        zIndex: 1,
                        animation: isAnimating ? `${loginAnimation} 0.5s ease forwards` : 'none',
                    }}>
                        <img
                            src={`astroVoortex.png`}
                            alt="Astro"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px',
                        }}
                    >
                        <Box
                            className="login-box"
                            sx={{
                                width: { xs: '90%', md: '40%', lg: '25%' },
                                padding: '40px',
                                borderRadius: '15px',
                                backgroundColor: 'rgba(19,19,22,0.90)',
                                boxShadow: 'rgba(91, 223, 237, 0.3) 0px 54px 55px, rgba(91, 223, 237, 0.3) 0px -12px 30px',
                                animation: isAnimating ? `${loginAnimation} 0.5s ease forwards` : 'none',  // Solo activa la animación si isAnimating es true
                            }}
                        >
                            {/* Logo */}
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                <img src="/logo.png" alt="Logo" style={{ height: 'auto', width: "70%" }} />
                            </Box>

                            <Typography variant="h4" sx={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}>
                                Bienvenido
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={handleAuth0Login}
                                        fullWidth
                                        sx={{
                                            background: 'linear-gradient(to right, #0064FB, #1ECDF8)',
                                            height: '45px',
                                            textTransform: 'none',
                                            fontSize: '16px',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                            },
                                        }}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                </Grid>

                                {/* Botones de Google y Facebook con imágenes */}
                                <Grid item xs={12}>
                                    <Typography variant="body2" sx={{ color: 'white', textAlign: 'center', margin: '10px 0' }}>
                                        o continúa con
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => loginWithRedirect({ authorizationParams: { connection: 'google-oauth2', prompt: "login" } })}
                                            sx={{
                                                marginRight: '10px',
                                                borderColor: 'gray',
                                                color: 'white',
                                                borderRadius: '10px',
                                                textTransform: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    borderColor: '#1ECDF8',
                                                },
                                            }}
                                        >
                                            <img src="google.png" alt="Google" style={{ width: '20px', marginRight: '10px', borderRadius: "50%" }} />
                                            Google
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => loginWithRedirect({ authorizationParams: { connection: 'facebook', prompt: "login" } })}
                                            sx={{
                                                borderColor: 'gray',
                                                color: 'white',
                                                borderRadius: '10px',
                                                textTransform: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    borderColor: '#1ECDF8',
                                                },
                                            }}
                                        >
                                            <img src="Facebook.png" alt="Facebook" style={{ width: '20px', marginRight: '10px', borderRadius: "50%" }} />
                                            Facebook
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>)}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Login;

import Box from '@mui/material/Box';
import { Grid, Skeleton, Typography} from '@mui/material';

const SearchPublisher = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box sx={{ padding: { xs: "5px", md: "20px" }, width: "95%", borderRadius: "20px" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                <Box display="flex">
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: "20px" }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: "20px" }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box display="flex" mt={"30px"}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: "20px" }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Skeleton variant="rectangular" width="100%" height={80} sx={{ borderRadius: "20px" }} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", mt:"20px" }}>
                                    <Skeleton variant="text" width="30%" height={40} sx={{ marginBottom: "20px" }} />
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}>
                                        <Box sx={{ width: "28%" }}></Box>
                                        <Skeleton variant="text" width="5%" height={40} sx={{ marginBottom: "20px" }} style={{ marginLeft: "auto" }} />
                                        <Skeleton variant="text" width="20%" height={40} sx={{ marginBottom: "20px" }} style={{ marginLeft: "auto" }} />
                                        <Skeleton variant="text" width="20%" height={40} sx={{ marginBottom: "20px" }} style={{ marginLeft: "auto" }} />
                                        <Skeleton variant="text" width="20%" height={40} sx={{ marginBottom: "20px" }} style={{ marginLeft: "auto" }} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={450} sx={{ borderRadius: "20px" }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={450} sx={{ borderRadius: "20px" }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={450} sx={{ borderRadius: "20px" }} />
                            </Grid>
                            <Grid item xs={3}>
                                <Skeleton variant="rectangular" width="100%" height={450} sx={{ borderRadius: "20px" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default SearchPublisher;
import Box from '@mui/material/Box';
import { Grid, Typography, useTheme } from '@mui/material';
import ClientsSettings from './ClientsSettings';
import AnswerSettings from './AnswerSettings';
import AnswerFastSettings from './AnswerFastSettings';
import { useState } from 'react';
import { tokens } from '../../../theme';

const AppSettings = () => {
  const [answers, setAnswers] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSave = (title, message) => {
    setAnswers((prevAnswers) => [
      ...prevAnswers,
      { id: prevAnswers.length + 1, title, message }
    ]);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <Grid container direction="column" alignItems="center" sx={{ width: "100%" }}>
        <Grid item sx={{ width: "100%", marginTop: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Box sx={{ width: "100%", borderRadius: "20px" }}>
              <Grid container>
                <Grid item xs={12}>
                <ClientsSettings clients={'Hola'} />
                </Grid>
                <Grid item xs={12} sx={{
                  bgcolor: colors.primary[400],
                  borderRadius: "15px",
                  padding: "35px",
                  marginTop:'15px',
                  boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                }}>
                  <AnswerSettings onSave={handleSave} />
                  <AnswerFastSettings answers={answers} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppSettings;

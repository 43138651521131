const listProducts = [
    {
        "sku": "5755407",
        "number_part": "Z15S",
        "title": "MacBook Air de 13 pulgadas: Chip M2 de Apple con CPU de ocho nucleos y GPU de ocho nucleos, 256 GB SSD - Gris espacial 16GB",
        "price": "6700189.0000",
        "disponibility": "11",
        "detail": { "procesador": "M2", "almacenamiento": "256 GB SSD", "ram": "16 GB", "pantalla": "13in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "APPLE",
        "image": "https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp",
        "shipping_time": 5
    },
    {
        "sku": "4462693",
        "number_part": "3UR69AA#ABM",
        "title": "PC Portatil HP 24-F008La 23,8 NT, Ci3, 4GB, 1TB, NoDVD, Win10, Negra",
        "price": "1945888.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i3", "almacenamiento": "1 TB HDD", "ram": "4 GB", "pantalla": "23,8in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_856676-MCO70805083375_072023-V.webp",
        "shipping_time": 7
    },
    {
        "sku": "4466030",
        "number_part": "4PF37LA#ABM",
        "title": "PC Portatil HP 14-CK0033LA COREI5-7200U 8GB 1TB 14IN FREEDOS COLOR-BLUE",
        "price": "1732293.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i5", "almacenamiento": "1 TB HDD", "ram": "8 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_728808-MCO70856135694_082023-V.webp",
        "shipping_time": 6
    },
    {
        "sku": "4496749",
        "number_part": "6WV37LT#ABM",
        "title": "HP ProBook 430 G6, IntelAR Core# i5-8265U, WindowsAR 10 Professional 64bit 8GB DDR4, SSD 256GB",
        "price": "3569209.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i5", "almacenamiento": "256 GB SSD", "ram": "8 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_753832-MCO43109945791_082020-V.webp",
        "shipping_time": 8
    },
    {
        "sku": "4805051",
        "number_part": "4PG17LA#ABM",
        "title": "PC Portatil HP 14-ck0040la i3 4GB 1TB 14 Linux Negro",
        "price": "1329628.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i3", "almacenamiento": "1 TB HDD", "ram": "4 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_937021-MCO43248628990_082020-V.webp",
        "shipping_time": 4
    },
    {
        "sku": "4829872",
        "number_part": "3J042LT#ABM",
        "title": "Portatil HP ProBook 450 G7, i7-10510U, Win10P, 8GB, Video MX130 2GB, SSD 512GB, 15.6, 1/1/0",
        "price": "3528000.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i7", "almacenamiento": "512 GB SSD", "ram": "8 GB", "pantalla": "15,6in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://microcell.co/26522-medium_default/portatil-hp-probook-450-g7-core-i7-10510u-8gb-512gb-ssd-156-video-2gb-w10p.jpg",
        "shipping_time": 6
    },
    {
        "sku": "4829873",
        "number_part": "3J342LT#ABM",
        "title": "Portatil HP ProBook 440 G7, i7-10510U, Win10P, 8GB, HDD 1TB, 14, 1/1/0",
        "price": "3530827.0000",
        "disponibility": "12",
        "detail": { "procesador": "Intel Core i7", "almacenamiento": "1 TB HDD", "ram": "8 GB", "pantalla": "14in" },
        "category_name": "Computadores Portátiles",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_929407-MCO70535817864_072023-V.webp",
        "shipping_time": 9
    },
    {
        "sku": "5615451",
        "number_part": "1Z978AA#ABA",
        "title": "28.0IN UHD 60Hz 5ms DP HDMI USB-C VESA Height Adjustable",
        "price": "2325451.0000",
        "disponibility": "12",
        "detail": { "tamaño": "28in", "resolucion": "3840x2160", "calidad": "UHD", "entrada": "HDMI Y USB-C" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://i0.wp.com/www.inovamusicnet.com/wp-content/uploads/2023/01/71J8MZHhiQL.AC_SL600_.jpg?fit=600%2C409&ssl=1",
        "shipping_time": 3
    },
    {
        "sku": "5615452",
        "number_part": "22J05AA#ABA",
        "title": "24.5IN Res. FHD (1920 x 1080) Tasa 165 Hz 1MS Color Negro",
        "price": "1195384.0000",
        "disponibility": "8",
        "detail": { "tamaño": "24,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_610431-MLA54052812183_022023-O.webp",
        "shipping_time": 6
    },
    {
        "sku": "5615453",
        "number_part": "25Y22AA#ABA",
        "title": "18.5IN HD 60Hz 5ms HDMI VGA VESA -Color Negro",
        "price": "522938.0000",
        "disponibility": "8",
        "detail": { "tamaño": "18,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI Y VGA" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_687018-MLA44063922326_112020-O.webp",
        "shipping_time": 4
    },
    {
        "sku": "5615454",
        "number_part": "2H2K2AA#ABA",
        "title": "21.5IN Res. FHD (1920 x 1080) Tasa 75 Hz 5MS HDMI VGA Color Negro",
        "price": "730625.0000",
        "disponibility": "8",
        "detail": { "tamaño": "21,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI Y VGA" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp",
        "shipping_time": 7
    },
    {
        "sku": "5615455",
        "number_part": "2V6B5AA#ABA",
        "title": "24.5IN Res. FHD (1920 x 1080) Tasa 144 Hz 1MS HDMI Color Negro",
        "price": "1835390.0000",
        "disponibility": "8",
        "detail": { "tamaño": "24,5in", "resolucion": "1920x1080", "calidad": "FHD", "entrada": "HDMI" },
        "category_name": "Monitores y Pantallas",
        "brand_name": "HP",
        "image": "https://http2.mlstatic.com/D_NQ_NP_862965-MCO54833228087_042023-V.webp",
        "shipping_time": 5
    },
]

const sellers = [
    { name: "Osneider Scarpetta", total: "103M", image: "/top.jpg" },
    { name: "David Acevedo", total: "92M", image: "/top2.jpeg" },
    { name: "Carlos Gallego", total: "91M", image: "/top3.jpeg" },
    { name: "Ana Martínez", total: "70M", image: "/profile.jpg" },
    { name: "Elena González", total: "67M", image: "/profile.jpg" },
    { name: "Miguel Sánchez", total: "65M", image: "/profile.jpg" },
    { name: "Laura Fernández", total: "59M", image: "/profile.jpg" },
    { name: "Sofía Ramírez", total: "55M", image: "/profile.jpg" }
];

const questions = [
    {
        question: "Hola, ¿cuándo me llega el pedido 5755407?",
        image: "https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp",
        title: "MacBook Air de 13 pulgadas: Chip M2 de Apple...",
        price: "6.700.189",
        user: "JUAN123",
        isCustomer: true
    },
    {
        question: "Quisiera saber si el modelo 4PF37LA#ABM tiene garantía extendida.",
        image: "https://http2.mlstatic.com/D_NQ_NP_728808-MCO70856135694_082023-V.webp",
        title: "PC Portatil HP 14-CK0033LA COREI5-7200U...",
        price: "1.732.293",
        user: "MARIA_GH",
        isCustomer: true
    },
    {
        question: "¿El pedido 5615451 incluye cable HDMI?",
        image: "https://i0.wp.com/www.inovamusicnet.com/wp-content/uploads/2023/01/71J8MZHhiQL.AC_SL600_.jpg?fit=600%2C409&ssl=1",
        title: "28.0IN UHD 60Hz 5ms DP HDMI USB-C...",
        price: "2.325.451",
        user: "LUIS_FERNANDO",
        isCustomer: true
    },
    {
        question: "¿Puedo recoger el pedido 5615455 en tienda física?",
        image: "https://http2.mlstatic.com/D_NQ_NP_862965-MCO54833228087_042023-V.webp",
        title: "24.5IN Res. FHD (1920 x 1080)...",
        price: "1.835.390",
        user: "KAREN_LOPEZ",
        isCustomer: true
    },
    {
        question: "¿El modelo 4829873 tiene entrega el mismo día?",
        image: "https://http2.mlstatic.com/D_NQ_NP_929407-MCO70535817864_072023-V.webp",
        title: "Portatil HP ProBook 440 G7, i7-10510U...",
        price: "3.530.827",
        user: "DIEGO_OP",
        isCustomer: true
    },
    {
        question: "Buen día, ¿tienen este modelo en otros colores?",
        image: "https://http2.mlstatic.com/D_NQ_NP_856676-MCO70805083375_072023-V.webp",
        title: "PC Portatil HP 24-F008La 23,8 NT...",
        price: "1.945.888",
        user: "JOSE_MARTINEZ",
        isCustomer: false
    },
    {
        question: "Hola, ¿tienen disponibilidad en almacén físico?",
        image: "https://microcell.co/26522-medium_default/portatil-hp-probook-450-g7-core-i7-10510u-8gb-512gb-ssd-156-video-2gb-w10p.jpg",
        title: "Portatil HP ProBook 450 G7, i7-10510U...",
        price: "3.528.000",
        user: "CAMILA_GA",
        isCustomer: false
    },
    {
        question: "¿El precio incluye impuestos?",
        image: "https://http2.mlstatic.com/D_NQ_NP_610431-MLA54052812183_022023-O.webp",
        title: "24.5IN Res. FHD (1920 x 1080)...",
        price: "1.195.384",
        user: "FERNANDO78",
        isCustomer: false
    },
    {
        question: "¿Hay descuento por comprar más de dos unidades?",
        image: "https://http2.mlstatic.com/D_NQ_NP_937021-MCO43248628990_082020-V.webp",
        title: "PC Portatil HP 14-ck0040la i3 4GB...",
        price: "1.329.628",
        user: "ANA_LP",
        isCustomer: false
    },
    {
        question: "¿Ofrecen crédito para este modelo?",
        image: "https://http2.mlstatic.com/D_NQ_NP_753832-MCO43109945791_082020-V.webp",
        title: "HP ProBook 430 G6, IntelAR Core# i5-8265U...",
        price: "3.569.209",
        user: "ROBERTO_GOMEZ",
        isCustomer: false
    }        
];

const sampleRows = [
    {
        id: 1,
        date: '2024-12-24',
        title: 'Telefono',
        id_venta: '1',
        id_meli: '2',
        sku: '3002442572346',
        qty: '500',
        qtyproduct: '2700',
        qtyMeli: '1700',
        qtyAmazon: '700',
        qtySale: '2500',
        qtyNational: '700',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '2700',
    },
    {
        id: 2,
        date: '2024-12-24',
        title: 'Smartphone Samsung Galaxy S21',
        id_venta: '2',
        id_meli: '3',
        sku: 'SGS21',
        qty: '300',
        qtyproduct: '1500',
        qtyMeli: '800',
        qtyAmazon: '400',
        qtySale: '1600',
        qtyNational: '500',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '600000',
    },
    {
        id: 3,
        date: '2024-12-24',
        title: 'Monitor LG UltraWide 34"',
        id_venta: '3',
        id_meli: '4',
        sku: 'LGUW34',
        qty: '200',
        qtyproduct: '1000',
        qtyMeli: '600',
        qtyAmazon: '300',
        qtySale: '1200',
        qtyNational: '400',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '320000',
    },
    {
        id: 4,
        date: '2024-12-24',
        title: 'Teclado Mecánico Corsair K95',
        id_venta: '4',
        id_meli: '5',
        sku: 'CMK95',
        qty: '100',
        qtyproduct: '500',
        qtyMeli: '250',
        qtyAmazon: '100',
        qtySale: '550',
        qtyNational: '150',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '200000',
    },
    {
        id: 5,
        date: '2024-12-24',
        title: 'Cámara Sony Alpha A6400',
        id_venta: '5',
        id_meli: '6',
        sku: 'SAA6400',
        qty: '50',
        qtyproduct: '250',
        qtyMeli: '150',
        qtyAmazon: '100',
        qtySale: '270',
        qtyNational: '80',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '1200000',
    },
    {
        id: 6,
        date: '2024-12-24',
        title: 'Tablet Apple iPad Pro 11"',
        id_venta: '6',
        id_meli: '7',
        sku: 'IPDPRO11',
        qty: '75',
        qtyproduct: '350',
        qtyMeli: '200',
        qtyAmazon: '150',
        qtySale: '400',
        qtyNational: '120',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '1000000',
    },
    {
        id: 7,
        date: '2018-12-24',
        title: 'Audífonos Sony WH-1000XM4',
        id_venta: '7',
        id_meli: '8',
        sku: 'WH1000XM4',
        qty: '150',
        qtyproduct: '750',
        qtyMeli: '450',
        qtyAmazon: '250',
        qtySale: '850',
        qtyNational: '300',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '300000',
    },
    {
        id: 8,
        date: '2019-12-24',
        title: 'Smartwatch Apple Watch Series 6',
        id_venta: '8',
        id_meli: '9',
        sku: 'AWATCH6',
        qty: '120',
        qtyproduct: '600',
        qtyMeli: '350',
        qtyAmazon: '200',
        qtySale: '650',
        qtyNational: '250',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 9,
        date: '2018-12-24',
        title: 'Consola PlayStation 5',
        id_venta: '9',
        id_meli: '10',
        sku: 'PS5',
        qty: '80',
        qtyproduct: '400',
        qtyMeli: '200',
        qtyAmazon: '150',
        qtySale: '450',
        qtyNational: '170',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '1500000',
    },
    {
        id: 10,
        date: '2019-11-24',
        title: 'Cámara GoPro Hero 9',
        id_venta: '10',
        id_meli: '11',
        sku: 'GPHERO9',
        qty: '60',
        qtyproduct: '300',
        qtyMeli: '150',
        qtyAmazon: '100',
        qtySale: '350',
        qtyNational: '120',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '600000',
    },
    {
        id: 11,
        date: '2022-11-24',
        title: 'Disco Duro Externo Seagate 2TB',
        id_venta: '11',
        id_meli: '12',
        sku: 'SG2TB',
        qty: '250',
        qtyproduct: '1250',
        qtyMeli: '700',
        qtyAmazon: '400',
        qtySale: '1400',
        qtyNational: '500',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '100000',
    },
    {
        id: 12,
        date: '2024-03-24',
        title: 'Impresora HP DeskJet 3775',
        id_venta: '12',
        id_meli: '13',
        sku: 'HPDJ3775',
        qty: '180',
        qtyproduct: '900',
        qtyMeli: '500',
        qtyAmazon: '300',
        qtySale: '1000',
        qtyNational: '400',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '150000',
    },
    {
        id: 13,
        date: '2024-01-24',
        title: 'Router TP-Link Archer AX6000',
        id_venta: '13',
        id_meli: '14',
        sku: 'TPLAX6000',
        qty: '130',
        qtyproduct: '650',
        qtyMeli: '350',
        qtyAmazon: '200',
        qtySale: '700',
        qtyNational: '250',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '400000',
    },
    {
        id: 14,
        date: '2020-12-24',
        title: 'Monitor Samsung Odyssey G7',
        id_venta: '14',
        id_meli: '15',
        sku: 'ODG7',
        qty: '90',
        qtyproduct: '450',
        qtyMeli: '250',
        qtyAmazon: '150',
        qtySale: '500',
        qtyNational: '180',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '700000',
    },
    {
        id: 15,
        date: '2021-11-24',
        title: 'Tarjeta Gráfica NVIDIA RTX 3070',
        id_venta: '15',
        id_meli: '16',
        sku: 'RTX3070',
        qty: '70',
        qtyproduct: '350',
        qtyMeli: '200',
        qtyAmazon: '120',
        qtySale: '400',
        qtyNational: '150',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '1000000',
    },
    {
        id: 16,
        date: '2023-10-24',
        title: 'Smartphone Xiaomi Mi 11',
        id_venta: '16',
        id_meli: '17',
        sku: 'XM11',
        qty: '90',
        qtyproduct: '450',
        qtyMeli: '250',
        qtyAmazon: '150',
        qtySale: '500',
        qtyNational: '200',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '500000',
    },
    {
        id: 17,
        date: '2020-09-24',
        title: 'Auriculares Logitech G Pro X',
        id_venta: '17',
        id_meli: '18',
        sku: 'LGPROX',
        qty: '110',
        qtyproduct: '550',
        qtyMeli: '300',
        qtyAmazon: '150',
        qtySale: '600',
        qtyNational: '220',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '150000',
    },
    {
        id: 18,
        date: '2020-03-24',
        title: 'Disco Duro SSD Samsung 970 EVO Plus 1TB',
        id_venta: '18',
        id_meli: '19',
        sku: 'SSD9701TB',
        qty: '160',
        qtyproduct: '800',
        qtyMeli: '400',
        qtyAmazon: '200',
        qtySale: '900',
        qtyNational: '320',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '300000',
    },
    {
        id: 19,
        date: '2020-03-24',
        title: 'Tablet Samsung Galaxy Tab S7',
        id_venta: '19',
        id_meli: '20',
        sku: 'SGTABS7',
        qty: '70',
        qtyproduct: '350',
        qtyMeli: '200',
        qtyAmazon: '100',
        qtySale: '400',
        qtyNational: '130',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '700000',
    },
    {
        id: 20,
        date: '2023-01-24',
        title: 'Mouse Logitech MX Master 3',
        id_venta: '20',
        id_meli: '21',
        sku: 'MXMASTER3',
        qty: '130',
        qtyproduct: '650',
        qtyMeli: '350',
        qtyAmazon: '200',
        qtySale: '700',
        qtyNational: '250',
        shipping: 'Amazon',
        shippingInternational: 'Colombia',
        utility: '200000',
    },
];

const cardsData = [
        {
            order: '1284765623467365',
            title: 'MacBook Air 13"',
            price: '6´700.189',
            image: 'https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp',
            track: '54387235432',
            status: 'Pagada',
            tracking: 'No Registrada',
        },
        {
            order: '7364598345629384',
            title: 'PC Portátil HP 24-F008La',
            price: '1´945.888',
            image: 'https://http2.mlstatic.com/D_NQ_NP_856676-MCO70805083375_072023-V.webp',
            track: '90876345211',
            status: 'En Proceso',
            tracking: 'No Registrada',
        },
        {
            order: '3248756342357563',
            title: 'HP ProBook 430 G6',
            price: '3´569.209',
            image: 'https://http2.mlstatic.com/D_NQ_NP_753832-MCO43109945791_082020-V.webp',
            track: '67854312976',
            status: 'Pagada',
            tracking: 'AMZ567894',
        },
        {
            order: '9987542312345567',
            title: 'PC Portátil HP 14-ck0040la',
            price: '1´329.628',
            image: 'https://http2.mlstatic.com/D_NQ_NP_937021-MCO43248628990_082020-V.webp',
            track: '43527898223',
            status: 'Enviada',
            tracking: 'DHL783219',
        },
        {
            order: '5678324523458953',
            title: 'Portátil HP ProBook 450 G7',
            price: '3´528.000',
            image: 'https://microcell.co/26522-medium_default/portatil-hp-probook-450-g7-core-i7-10510u-8gb-512gb-ssd-156-video-2gb-w10p.jpg',
            track: '34789234567',
            status: 'En Proceso',
            tracking: 'No Registrada',
        },
        {
            order: '8321567345893241',
            title: 'Monitor HP 28.0IN UHD',
            price: '2´325.451',
            image: 'https://i0.wp.com/www.inovamusicnet.com/wp-content/uploads/2023/01/71J8MZHhiQL.AC_SL600_.jpg?fit=600%2C409&ssl=1',
            track: '21543876542',
            status: 'Pagada',
            tracking: 'UPS345783',
        },
        {
            order: '2345897645213789',
            title: 'Monitor HP 24.5IN FHD',
            price: '1´195.384',
            image: 'https://http2.mlstatic.com/D_NQ_NP_610431-MLA54052812183_022023-O.webp',
            track: '98456341237',
            status: 'Cancelada',
            tracking: 'No Registrada',
        },
        {
            order: '7863421896543872',
            title: 'Monitor HP 21.5IN FHD',
            price: '730.625',
            image: 'https://http2.mlstatic.com/D_NQ_NP_801112-MLA46516512347_062021-V.webp',
            track: '54837298561',
            status: 'En Proceso',
            tracking: 'DHL987654',
        },
        {
            order: '3124579821435769',
            title: 'Monitor HP 18.5IN HD',
            price: '522.938',
            image: 'https://http2.mlstatic.com/D_NQ_NP_687018-MLA44063922326_112020-O.webp',
            track: '98432576219',
            status: 'Enviada',
            tracking: 'AMZ123456',
        },
        {
            order: '4357289854123768',
            title: 'Monitor HP 24.5IN FHD 144Hz',
            price: '1´835.390',
            image: 'https://http2.mlstatic.com/D_NQ_NP_862965-MCO54833228087_042023-V.webp',
            track: '14325798432',
            status: 'Pagada',
            tracking: 'UPS987123',
        },    
];


module.exports = { listProducts, sellers, questions, sampleRows , cardsData};

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const Questions = ({ question, image, title, price, user, isCustomer }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box
            sx={{
                background: colors.primary[400],
                padding: { xs: "12px", md: "30px" },
                width: "100%",
                borderRadius: "15px",
                boxShadow: "rgba(50, 40, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                color: colors.primary[100],
                marginBottom: "20px",
                marginLeft: {xs:"0px", md:"15px"},
                minheight: "250px"
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={6} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                    <Typography variant="h5" sx={{ fontFamily: 'Arial, sans-serif', marginBottom: "10px" }}>{user}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ background: colors.primary[980], padding: "10px", borderRadius: '10px', marginLeft: "10px" }}>
                    <Typography variant="body1" sx={{ paddingLeft: '30px', fontFamily: 'Arial, sans-serif', marginBottom: "5px", color: colors.primary[100] }}>{question} </Typography>
                </Grid>

                <Grid item xs={6} lg={3}>
                    <Box sx={{ display: "flex", width: '120px', height: '80px', objectFit: 'contain', borderRadius: '20px', bgcolor: "white", padding: "5px" }}>
                        <img src={image} alt={title} style={{ width: '100%', height: 'auto', objectFit: 'contain', borderRadius: '20px' }} />
                    </Box>
                </Grid>

                <Grid item xs={6} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
                    <Typography
                        variant="h5"
                        sx={{
                            paddingLeft: "10px",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2, // Limita el texto a 2 líneas
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography variant="h5" sx={{ paddingLeft: "10px" }}>{price}</Typography>
                </Grid>

                {isCustomer ? (
                    <>
                        <Grid item xs={12} lg={6} sx={{ display: 'flex', flexDirection: {xs:"column", lg:"row"}, justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%',background: "linear-gradient(to right, #0064FB, #1ECDF8)", margin:"3px" }}>
                                Ir Al Chat
                            </Button>
                            <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', background: "linear-gradient(to right, #FF5733, #FF0000)", margin:"3px" }}>
                                Información
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} lg={6} sx={{ display: 'flex', flexDirection: {xs:"column", lg:"row"}, justifyContent: 'center', alignItems: 'center'}}>
                            <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', background: "linear-gradient(to right, #0064FB, #1ECDF8)", margin:"3px" }}>
                                Responder
                            </Button>
                            <Button variant="contained" color="primary" fullWidth sx={{ textTransform: 'none', fontFamily: 'Arial, sans-serif', borderRadius: '10px', height: '45px', width: '100%', background: "linear-gradient(to right, #FF5733, #FF0000)", margin:"3px" }}>
                                Actualizar
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default Questions;

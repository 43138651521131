import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, MenuItem, Select, useTheme } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import { tokens } from "../../../theme";
import BoxButton from "../../Global/BoxButton/BoxButton";

const BarComponent = ({ activepage }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const handleSelectChange = (event) => {
        navigate(`/settings/${event.target.value}`); // Redirige a la página seleccionada
    };
    return (
        <Box>
            <Box sx={{ backgroundColor: colors.primary[980], display: { xs: "flex", md: "none" } }}>
                <Select
                    value={activepage}
                    onChange={handleSelectChange}
                    variant="outlined"
                    sx={{ width: '100%', display: "flex", flexDirection: "row" }}
                    size="medium"
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                backgroundColor: colors.primary[980],
                            },
                        },
                    }}
                >
                    <MenuItem value="profile">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <ManageAccountsIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Datos Personales
                        </Box>
                    </MenuItem>
                    <MenuItem value="price">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <PriceChangeIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Precios y Márgenes
                        </Box>
                    </MenuItem>
                    <MenuItem value="shipping">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <LocalShippingIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Lógistica y Envío
                        </Box>
                    </MenuItem>
                    <MenuItem value="app">
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center" }}>
                            <AppSettingsAltIcon style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            Configuración App
                        </Box>
                    </MenuItem>
                </Select>
            </Box>
            <Grid container spacing={2} sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "profile" ? true : false}
                        icon={<ManageAccountsIcon />}
                        title="Datos Personales"
                        buttonText="Ajustar"
                        onClick="/settings/profile"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "price" ? true : false}
                        icon={<PriceChangeIcon />}
                        title="Precios y Márgenes"
                        buttonText="Ajustar"
                        onClick="/settings/price"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "shipping" ? true : false}
                        icon={<LocalShippingIcon />}
                        title="Lógistica y Envío"
                        buttonText="Ajustar"
                        onClick="/settings/shipping"
                    />
                </Grid>
                <Grid item xs={6}>
                    <BoxButton
                        active={activepage === "app" ? true : false}
                        icon={<AppSettingsAltIcon/>}
                        title="Configuración App"
                        buttonText="Ajustar"
                        onClick="/settings/app"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default BarComponent;

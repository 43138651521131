import Box from '@mui/material/Box';
import { Grid, Typography, TextField, Button, useTheme } from '@mui/material';
import { useState } from 'react';
import { tokens } from '../../../theme';

const AnswerSettings = ({ onSave }) => {
    const [localTitle, setLocalTitle] = useState('');
    const [localMessage, setLocalMessage] = useState('');

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleTitleChange = (event) => {
        setLocalTitle(event.target.value);
    };

    const handleMessageChange = (event) => {
        setLocalMessage(event.target.value);
    };

    const handleSave = () => {
        onSave(localTitle, localMessage);
        setLocalTitle('');
        setLocalMessage('');
    };

    return (
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{ fontWeight: "600", textAlign: "center", marginBottom: "10px", color: colors.primary[100] }}>
                        Agregar nueva respuesta rápida
                    </Typography>
                    <Typography variant="h5" sx={{ marginBottom: "10px", color: colors.primary[100] }}>Título</Typography>
                    <Grid item xs={12} sx={{ padding: "5px", borderRadius: '10px', bgcolor: colors.primary[980] }}>
                        <TextField
                            value={localTitle}
                            onChange={handleTitleChange}
                            placeholder='Digita el título'
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    color: colors.primary[100],
                                },
                            }}
                            sx={{ borderRadius: '10px'}}
                        />
                    </Grid>

                    <Typography variant="h5" sx={{ marginBottom: "10px", marginTop: '15px', color: colors.primary[100] }}>Mensaje</Typography>
                    <Grid item xs={12} sx={{ padding: "5px", borderRadius: '10px', marginTop: '12px', bgcolor: colors.primary[980] }}>
                        <TextField
                            value={localMessage}
                            onChange={handleMessageChange}
                            placeholder='Digita el mensaje'
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            InputProps={{
                                sx: {
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                    },
                                    color: colors.primary[100],
                                },
                            }}
                            sx={{ borderRadius: '10px'}}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3} sx={{ alignItems: "center" }}>
                        <Button variant="contained" fullWidth sx={{ marginTop: '20px', borderRadius: '10px', height: '45px', fontSize: '16px', background: "linear-gradient(to right, #0064FB, #1ECDF8)" }} onClick={handleSave}>
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AnswerSettings;

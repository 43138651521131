import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { tokens } from '../../../theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const TotalProducts = ({ total, percent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        display:{xs:"none", md:"flex"},
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.primary[500],
        borderRadius: '15px',
        padding: '16px',
        color: 'white',
        width: '200px',
        height: "60px",
        marginRight: "20px",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: colors.primary[400], borderRadius: "50%", padding:"5px"}} 
        >
          <ShoppingCartIcon fontSize="large" sx={{color: colors.primary[100]}}/>
          </Box>
          <Box ml={2} >
            <Typography variant="h3">{total}</Typography>
            <Typography variant="body2">Activos</Typography>
          </Box>
        </Box>
        <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", alignSelf:"flex-start", width:"30%"}}>
          <Typography variant="body1" sx={{ color: '#2ECC71', fontWeight: 'bold' }}>
            {percent}%
          </Typography>
        </Box>
      </Box>
      );
};

      export default TotalProducts;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const formatPrice = (price) => {
  return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(price);
};

const Card = ({ images, title, amazonPrice, rating, link, onUpdate, actionType, buttonText }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const imagenProduct = images || "https://ventaequipos.com/shop/assets/default_image.png";
  const defaultImage = "https://ventaequipos.com/shop/assets/default_image.png";
  const [imageSrc, setImageSrc] = useState(imagenProduct);

  const maxRetries = 5;
  const handleImageError = (event) => {
    if (event.target.retryCount === undefined || event.target.retryCount === null) {
      event.target.retryCount = 1;
    } else if (event.target.retryCount < maxRetries) {
      event.target.retryCount++;
      setTimeout(() => {
        setImageSrc(event.target.src);
      }, 1000);
      return;
    }
    setImageSrc(defaultImage);
  };

  return (
    <Box sx={{ background: colors.primary[400], padding: { xs: "12px", md: "30px" }, minHeight: { xs: "200px", md: "420px" }, borderRadius: "15px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "5px", marginTop: "10px", height: { xs: "80px", md: "150px" }, padding: "10px", bgcolor: "#ffffff" }}>
        <img
          src={imageSrc}
          onError={handleImageError}
          alt={title}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Box>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: "600",
          marginTop: "10px",
          marginBottom: "10px",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden"
        }}
      >
        {title}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h6">{buttonText === "Modificar"? "Precio ML" : "Precio Amazon"}</Typography>
          <Typography variant="h5" sx={{ color: "#0064FB" }}>{formatPrice(amazonPrice)}</Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: { xs: "none", md: "block" } }}>
          <Typography variant="h6">{buttonText === "Modificar"? "Unidades" : "Rating"}</Typography>
          <Typography variant="h5" sx={{ color: "#0064FB" }}>{rating}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body1">{buttonText === "Modificar"? "Enlace ML" : "Enlace Amazon"}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" sx={{ color: "#0064FB" }}>
            <a href={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Link</a>
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Button
          variant="contained"
          sx={{ width: "100%", mt: "15px", borderRadius: "10px", background: "linear-gradient(to right, #0064FB, #1ECDF8)" }} onClick={actionType === 'update' ? onUpdate : null}>
          {buttonText}

        </Button>
      </Box>
    </Box>
  );
};

export default Card;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Button, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const BoxButton = ({ active, icon, title, buttonText, onClick, status }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(onClick);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 2,
        borderRadius: "15px",
        background: active ? "linear-gradient(to right, #0064FB, #1ECDF8)" : colors.primary[980],
        height: "73px",
        width: "100%",
        position: 'relative', // Necesario para posicionar la etiqueta
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
      }}
    >
      {status === 'develop' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#FF9800',
            color: '#fff',
            padding: '2px 8px',
            borderRadius: '15px 1px 5px 1px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        >
          En desarrollo
        </Box>
      )}
      <Box sx={{ padding: "10px", display: "flex", justifyContent: "center", marginLeft: "10px", bgcolor: colors.primary[980], borderRadius: "50%" }}>
        {icon}
      </Box>
      <Typography variant="h5" sx={{ fontWeight: "600", width: "40%" }}>{title}</Typography>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          boxShadow: "none",
          background: active ? colors.primary[500] : "linear-gradient(to right, #0064FB, #1ECDF8)",
          width: "120px",
          borderRadius: "10px",
          '&:hover': {
            boxShadow: "none",
            fontWeight: !active ? "700" : "",
          },
        }}
      >
        {active ? "Seleccionado" : buttonText}
      </Button>
    </Box>
  );
};

export default BoxButton;

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ShopPublisher from '../../components/Publisher/ShopPublisher/ShopPublisher';
import Banner from '../../components/Global/Banner/Banner';
import UserBanner from '../../components/Global/UserBanner/UserBanner';
import TopSellers from '../../components/Global/TopSellers/TopSellers';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getProducts, getUserByID, getUserData } from '../../redux/actions';
import { useEffect } from 'react';
import { sellers } from '../../mockdata/Mockdata';

const Home = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
          try {
            await dispatch(getUserData());
            await dispatch(getProducts());
            await dispatch(getUserByID(process.env.REACT_APP_USERID));
          } catch (error) {
            Swal.fire({
              icon: 'info',
              title: 'Página en Mantenimiento',
              text: 'Por favor vuelva más tarde',
            });
          }
        };
        fetchData();
      }, [dispatch]);

    const TopUsers = useSelector((state) => state.TopUsers);
    const user = useSelector((state) => state.User);

    const calculateTimeSinceCreation = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);
    
        const diffInMs = now - createdDate;
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    
        if (diffInDays > 30) {
          const diffInMonths = Math.floor(diffInDays / 30);
          const remainingDays = diffInDays % 30;
          return `${diffInMonths} meses y ${remainingDays} días`;
        } else {
          return `${diffInDays} días`;
        }
      };
    
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box sx={{ padding: { xs: "0px 15px 0px 15px", md: "20px" }, width: "100%", borderRadius: "20px", marginLeft:{xs:"0px", md:"20px"} }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8} lg={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={6}>
                                <Banner/>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <UserBanner image={"/profile.png"} name={user?.name + " " + user?.lastname } city={user?.city} total={user?.total? user.total : "$ 103M"} time={"28 días"}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                        <ShopPublisher renderCard={4}/>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <TopSellers sellers={sellers? sellers : []}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Home;
